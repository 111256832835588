















































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import AuthApi from "@/api/auth/authApi";
import { removeToken } from "@/lib/http/api";
import { TranslateResult } from "vue-i18n";

function gtag_report_conversion(url?: any) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-352683225/78NdCOjLjcgDENmJlqgB",
    event_callback: callback,
  });
  return false;
}

@Component({})
export default class CreateAccount extends Vue {
  loading: boolean = false;
  responseMessage: string | TranslateResult = "";
  emailErrors: TranslateResult = "";
  accountData = {
    email: "",
    password: "",
  };

  @Watch("accountData.email")
  onChangeAccountData(val: string) {
    this.emailHasErrors(this.accountData.email);
  }

  throwError(error?: string) {
    this.responseMessage =
      error || "We could not properly create your company. Try again later.";
    this.loading = false;
    return false;
  }

  emailHasErrors(email: string) {
    if (
      /^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo|hotmail|outlook|siltapp|asdf))[a-zA-Z]{2,6}.*\.[a-zA-Z]{2,14}$/.test(
        email
      )
    ) {
      this.emailErrors = "";
      return false;
    }
    this.emailErrors = this.$t("email_validator_error_feedback");
    return this.emailErrors;
  }

  async signupHandler() {
    this.loading = true;
    if (this.emailHasErrors(this.accountData.email)) return;
    if (!this.accountData.email) return this.throwError("Email is missing");
    if (!this.accountData.password)
      return this.throwError("Password is missing");
    await this.createAccount();
    if (process.env.VUE_APP_ENVIRONMENT === "production") {
      if (!(await this.postPipedriveDeal())) return this.throwError();
      if (!(await this.postAccountPipedrive())) return this.throwError();
    }
    this.loading = false;
    this.$emit("continue", this.accountData);
  }

  async postPipedriveDeal(): Promise<string> {
    if (!this.$store.state.onboardingInfo.companyName)
      return Promise.resolve("");
    // Create Organization
    const pipeOrgRes = await axios.post(
      `${process.env.VUE_APP_PIPEDRIVE_API_URI}v1/organizations?api_token=${process.env.VUE_APP_PIPEDRIVE_API_TOKEN}`,
      {
        name: this.$store.state.onboardingInfo.companyName,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    if (!(pipeOrgRes && pipeOrgRes.data && pipeOrgRes.data.data.id)) {
      return Promise.resolve("");
    }
    // Create Deal
    const pipeDealRes = await axios
      .post(
        `${process.env.VUE_APP_PIPEDRIVE_API_URI}v1/deals?api_token=${process.env.VUE_APP_PIPEDRIVE_API_TOKEN}`,
        {
          title: this.$store.state.onboardingInfo.companyName,
          pipeline_id: 9,
          // STG fields:
          // "d8561531d83aca2742acf4b12c9557ecd9753d2c":
          // this.$store.state.onboardingInfo.foundUs,
          //This fields do not work in staging
          "904cdfaa05573c2f8ebe151574def276aaa3f1a8":
            this.$store.state.onboardingInfo.companySize,
          "034aacfcc61662bdc6312228c91f403dad7d5fd1":
            this.$store.state.onboardingInfo.foundUs,
          d770f0f733d01f65cf849abc76413b8f98153608:
            this.$store.state.onboardingInfo.countriesOperating,
          org_id: pipeOrgRes.data.data.id,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .catch(() => {
        this.responseMessage =
          "We could not properly create your company. Try again later.";
      });
    if (pipeDealRes && pipeDealRes.data && pipeDealRes.data.success) {
      this.$store.state.onboardingInfo.dealId = pipeDealRes.data.data.id;
      return Promise.resolve("true");
    } else {
      return Promise.resolve("");
    }
  }

  async postAccountPipedrive(): Promise<boolean> {
    if (
      this.accountData.email &&
      this.$store.state.onboardingInfo &&
      this.$store.state.onboardingInfo.dealId
    ) {
      const pipePersonRes = await axios.post(
        `${process.env.VUE_APP_PIPEDRIVE_API_URI}v1/persons?api_token=${process.env.VUE_APP_PIPEDRIVE_API_TOKEN}`,
        {
          name: this.accountData.email,
          email: [
            {
              value: this.accountData.email,
              primary: true,
              label: "onboarding-dashboard",
            },
          ],
          phone: this.$store.state.onboardingInfo.phoneNumber,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (!(pipePersonRes && pipePersonRes.data && pipePersonRes.data.data.id))
        return Promise.resolve(false);

      const pipeDealRes = await axios.put(
        `${process.env.VUE_APP_PIPEDRIVE_API_URI}v1/deals/${this.$store.state.onboardingInfo.dealId}?api_token=${process.env.VUE_APP_PIPEDRIVE_API_TOKEN}`,
        {
          person_id: pipePersonRes.data.data.id,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (!(pipeDealRes && pipeDealRes.data && pipeDealRes.data.data.id))
        return Promise.resolve(false);
      return Promise.resolve(true);
    }
    return Promise.resolve(true);
  }

  async createAccount() {
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_SILT_URL}v1/auth/register/`,
        {
          email: this.accountData.email.toLowerCase(),
          password: this.accountData.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Company-App-Id": process.env.VUE_APP_SILT_APP_ID,
          },
        }
      );
      if (res.status === 201 || res.status === 200) {
        try {
          gtag_report_conversion();
          // window.lintrk('track', { conversion_id: 12272642 });
          const res = await AuthApi.login({
            email: this.accountData.email.toLowerCase(),
            password: this.accountData.password,
          });
        } catch (error: any) {
          this.responseMessage =
            (error.response && error.response.data.error_description) ||
            (error.response && error.response.data.detail) ||
            (error.response && error.response.data.error) ||
            error.description;
        }
      } else {
        removeToken();
      }
    } catch (error: any) {
      if (
        (error.response && error.response.status === 422) ||
        (error.response && error.response.status === 401)
      ) {
        this.responseMessage = this.$t("create_admin_user_existing_email");
      }
    }
  }
}
