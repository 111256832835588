








































import Vue from "vue";
import FormsService from "@/api/forms/formsService";
import eventBus from "@/lib/utils";
import KYBRequirementQuestionnaireQuestionForm from "../components/KYBRequirementQuestionnaireQuestionForm.vue";

export default Vue.extend({
  name: "KYBVerificationRequirementsQuestionnaire",
  components: { KYBRequirementQuestionnaireQuestionForm },
  props: {
    questionnaireProp: { type: Array, required: true },
    formId: { type: String, required: true },
  },
  data() {
    return {
      addNewQuestion: false,
      emptyQuestion: {
        text: "",
        mandatory: true,
        type: "TEXT",
        choices: [],
      },
      questionnaire: [] as Array<any>,
      loading: false,
      localFormId: this.formId,
    };
  },
  watch: {
    questionnaireProp: {
      handler() {
        this.questionnaire = JSON.parse(JSON.stringify(this.questionnaireProp));
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    formsEnabled(): boolean {
      return this.$store.state.verificationFlow.config.forms_enabled;
    },
  },
  methods: {
    isLastQuestion(index: number): boolean {
      return index === this.questionnaire.length - 1;
    },
    addNewQuestionForm() {
      this.addNewQuestion = true;
      this.questionnaire.push({ ...this.emptyQuestion });
    },
    async onUpdateQuestionnaireForm(event: any) {
      await this.handleDeleteQuestion(event.id, true);
      await this.addQuestion(event);
    },
    async addQuestion(event: any) {
      try {
        this.loading = true;
        const { data } = await FormsService.addFormQuestion(
          this.$store.state.verificationFlow.app_id,
          this.localFormId,
          event
        );
        this.localFormId = data.id;
        this.onFormQuestionAdded(data.questions);
        eventBus.$emit("v-toast", {
          localizedKey: "successful_question_added_feedback",
          type: "success-toast",
        });
      } finally {
        this.loading = false;
      }
    },
    onFormQuestionAdded(event: any) {
      this.questionnaire = event;
      this.addNewQuestion = false;
    },
    onUpdateLocalQuestionnaire(index: number, event: any) {
      this.$set(this.questionnaire, index, event);
      this.addNewQuestion = false;
    },
    onCancelCreateForm() {
      this.questionnaire.pop();
      this.addNewQuestion = false;
    },
    async handleDeleteQuestion(
      questionId: string,
      isUpdated?: boolean,
      index?: number
    ) {
      if (questionId)
        this.questionnaire = await this.deleteQuestion(questionId, isUpdated);
      else if (typeof index === "number") this.questionnaire.splice(index, 1);
    },
    async deleteQuestion(questionId: string, isUpdated?: boolean) {
      try {
        this.loading = true;
        const { data } = await FormsService.deleteFormQuestion(
          this.$store.state.verificationFlow.app_id,
          this.localFormId,
          questionId
        );
        this.localFormId = data.id;
        if (!isUpdated)
          eventBus.$emit("v-toast", {
            localizedKey: "successful_question_deleted_feedback",
            type: "success-toast",
          });
        return data.questions;
      } finally {
        this.loading = false;
      }
    },
    async createNewFormWithQuestions() {
      const verificationFlowId = this.$store.state.verificationFlow.app_id;
      try {
        this.loading = true;
        if (!this.formsEnabled) {
          this.$emit("enableForms", true);
        }
        const res = await FormsService.createForm(verificationFlowId, {
          questions: this.questionnaire,
        });
        eventBus.$emit("v-toast", {
          localizedKey: "successful_form_created_feedback",
          type: "success-toast",
        });
        return res;
      } finally {
        this.loading = false;
      }
    },
  },
});
