











































import Vue from "vue";

export default Vue.extend({
  name: "KYBDeleteExtraDocumentModal",
  props: {
    extraDocumentSelected: { required: true, type: Object },
    showDeleteExtraDocumentModal: { required: true, type: Boolean },
    processExtraDocuments: { required: true, type: Function },
    extraDocuments: { required: true, type: Array },
  },
  data() {
    return {
      checkIfCanDelete: "",
      responseDeleteError: "",
      loading: false,
    };
  },
  methods: {
    onCloseKYBDeleteExtraDocumentModal() {
      this.$emit("showeDeleteExtraDocumentModal", false);
      this.checkIfCanDelete = "";
      this.responseDeleteError = "";
    },
    async updateKYBCompanyExtraDocuments() {
      try {
        if (this.checkIfCanDelete === this.extraDocumentSelected.display_name) {
          this.loading = true;
          const extraDocumentsUpdated = this.extraDocuments.filter(
            (extraDocument: any) =>
              extraDocument.s3_url !== this.extraDocumentSelected.url
          );
          this.onCloseKYBDeleteExtraDocumentModal();
          await this.processExtraDocuments(extraDocumentsUpdated);
        } else {
          this.responseDeleteError = "Extra Document name not matching";
        }
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {},
});
