































import Vue from "vue";
import { TranslateResult } from "vue-i18n";

interface User {
  id: string;
  email: string;
  first_name?: string;
  last_name?: string;
}

interface Answer {
  id: string;
  created_at: Date;
  raw_answer?: string;
  answered_by: User;
}

export default Vue.extend({
  name: "KYBFormsDetailAnswer",
  props: {
    answer: { type: Object as () => Answer, required: true },
    kybCompanyDetail: { type: Object, required: true },
    choices: {
      required: true,
      type: Array<string>,
    },
    type: { required: true, type: String },
  },
  computed: {
    displayedAnswer(): string | string[] | null | TranslateResult {
      if (this.type === "MULTISELECT" && this.selectedChoices.length) {
        return this.selectedChoices;
      }
      if (this.type === "DATE" && this.answer.raw_answer) {
        return this.$options.filters?.date
          ? this.$options.filters.date(this.answer.raw_answer)
          : this.answer.raw_answer;
      }
      if (this.type === "BOOLEAN") {
        return this.answer.raw_answer
          ? this.$t("switch_yes")
          : this.$t("switch_no");
      }
      return this.answer.raw_answer || null;
    },
    selectedChoices(): string[] {
      if (this.type !== "MULTISELECT" || !this.answer.raw_answer) return [];

      const indices = this.parseMultiSelect(this.answer.raw_answer);
      return indices.map((index) => this.choices[index] || "");
    },
  },
  methods: {
    getAnsweredBy(): string {
      const { first_name, last_name, email } = this.answer.answered_by;
      return first_name && last_name
        ? `${first_name.toLowerCase()} ${last_name.toLowerCase()}`
        : email;
    },
    getUserRoles(userId: string): string[] {
      if (this.kybCompanyDetail?.admin?.id === userId) {
        return this.kybCompanyDetail.admin.type;
      }
      return this.kybCompanyDetail.contact.type;
    },
    parseMultiSelect(answer: string | Array<string>): number[] {
      let parsedAnswer: number[] = [];

      if (typeof answer === "string") {
        try {
          parsedAnswer = JSON.parse(answer);
        } catch (error) {
          console.error("Invalid JSON format:", answer);
          return [];
        }
      } else {
        parsedAnswer = answer.map(Number);
      }

      return parsedAnswer
        .map((num) => num - 1)
        .filter((index) => index >= 0 && index < this.choices.length);
    },
  },
});
