import { formatUri } from "@/api";
import { HTTP } from "@/lib/http/api";

export default class FormsService {
  static async getVerificationFlowForms(verificationFlowId: string) {
    const res = await HTTP.get(
      formatUri`v1/company-apps/${verificationFlowId}/forms`
    );
    return res;
  }

  static async createForm(verificationFlowId: string, data: any) {
    const res = await HTTP.post(
      formatUri`v1/company-apps/${verificationFlowId}/forms`,
      data
    );
    return res;
  }

  static async addFormQuestion(
    verificationFlowId: string,
    formId: string,
    data: any
  ) {
    const res = await HTTP.post(
      formatUri`v1/company-apps/${verificationFlowId}/forms/${formId}/questions`,
      data
    );
    return res;
  }

  static async deleteFormQuestion(
    verificationFlowId: string,
    formId: string,
    questionId: string
  ) {
    const res = await HTTP.delete(
      formatUri`v1/company-apps/${verificationFlowId}/forms/${formId}/questions/${questionId}`
    );
    return res;
  }

  static async deleteForm(verificationFlowId: string) {
    const res = await HTTP.delete(
      formatUri`v1/company-apps/${verificationFlowId}/forms`
    );
    return res;
  }
}
