








































import Vue from "vue";
import UserDetail from "../users/UserDetail.vue";
import KYBUserStatusInfo from "./components/KYBUserStatusInfo.vue";
import KYBRepresentativeInformation from "./components/KYBRepresentativeInformation.vue";

export default Vue.extend({
  components: {
    UserDetail,
    KYBUserStatusInfo,
    KYBRepresentativeInformation,
  },
  name: "KYBRepresentativeDetail",
  props: {
    loading: { required: true, type: Boolean },
    uboDetail: { required: true, type: Object },
    KYBCompanyDetailPrincipals: { required: true, type: Array },
    showUBODetail: { required: true, type: Boolean },
    representativeInformation: { required: true, type: Object },
    isScreening: { required: true, type: Boolean },
  },
  computed: {
    representativeEmail(): string {
      return this.uboDetail && this.uboDetail.user && this.uboDetail.user.email;
    },
    representativeStatusPA(): boolean {
      return (
        this.uboDetail &&
        (!this.isUserWithoutAttempts(this.uboDetail.processing_attempts) ||
          !this.isUserWithoutAttempts(this.uboDetail.verifications))
      );
    },
    showRepresentativesList(): boolean {
      return !!(
        !this.loading &&
        this.KYBCompanyDetailPrincipals &&
        this.KYBCompanyDetailPrincipals.length &&
        !this.representativeStatusPA
      );
    },
    hasUBODetail(): boolean {
      return !!Object.keys(this.uboDetail).length;
    },
  },
  data() {
    return {};
  },
  methods: {
    isUserWithoutAttempts(processingAttempts: []) {
      if (!processingAttempts || processingAttempts.length === 0) return true;
      return false;
    },
  },
});
