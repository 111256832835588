



















































import { Vue, Prop, Component } from "vue-property-decorator";
import AuthApi from "@/api/auth/authApi";
import { emailErrors } from "@/lib/validators";
import { TranslateResult } from "vue-i18n";

@Component({})
export default class CreatePassword extends Vue {
  email = "";
  password = "";
  loading = false;
  responseMessage: string | TranslateResult = "";
  submitted = false;
  @Prop()
  emailProp?: string;

  get emailErrors() {
    return emailErrors(this.email, this);
  }

  async createPassword() {
    this.loading = true;
    try {
      this.submitted = false;
      await AuthApi.createPassword(
        {
          password: this.password,
        },
        (this.$router.currentRoute.query.temp_token as string) ||
          this.$store.state.companyAppTempToken
      );
      this.submitted = true;
    } catch (error: any) {
      if (error.response.status === 404) {
        this.responseMessage = this.$t("recover_token_already_used");
        this.loading = false;
        return;
      }

      this.responseMessage =
        (error.response && error.response.data.error_description) ||
        (error.response && error.response.data.detail) ||
        error.description ||
        (error.response && error.response.data.error) ||
        this.$t("unsucessful_feedback");
    }
    this.loading = false;
  }

  mounted() {
    if (this.emailProp) this.email = this.emailProp;
  }
}
