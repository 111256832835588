var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"extracted-information-detail__details"},[_c('div',{staticClass:"extracted-information-detail__details-header"},[_c('div',{staticClass:"extracted-information-detail__details-header-wrapper",on:{"click":function($event){_vm.activeSection = !_vm.activeSection}}},[_c('h3',[_vm._v(_vm._s(_vm.$t(_vm.sectionName)))]),_c('i',{staticClass:"appended-icon fa fa-chevron-down",class:{ active: _vm.activeSection }})]),_c('i',{staticClass:"fa fa-crosshairs focus-icon",class:{ active: _vm.focusSectionName === _vm.sectionName },on:{"click":_vm.trackEventAndEmit}})]),(
      _vm.activeSection && _vm.sectionName === 'documents_details_line_items_title'
    )?_c('div',{staticClass:"tabs-wrapper small"},_vm._l((_vm.lineItemsTabs),function(lineItem){return _c('VTab',{key:lineItem,staticClass:"pill",attrs:{"activeTabName":lineItem,"checkIfIsSelected":_vm.activeLineItemsTabs.find(function (tab) { return tab === lineItem; }) || ''},nativeOn:{"click":function($event){return _vm.updateTabsSelected(lineItem)}}},[_vm._v(" "+_vm._s(_vm.$t(lineItem))+" ")])}),1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSection),expression:"activeSection"}],staticClass:"extracted-information-detail__details-values",class:{
      'extracted-information-detail__details-values__table': !_vm.values.length,
      'extracted-information-detail__details-values-totals':
        _vm.sectionName === 'documents_details_totals_title',
    }},[(_vm.values.length)?[_vm._l((_vm.values),function(input,index){return [_c('PADetailCDPInput',{key:input.key,attrs:{"field":input,"index":index,"small":_vm.sectionName === 'documents_details_totals_title',"fieldType":_vm.getFieldType(input),"disabledDocumentSubtype":_vm.disabledDocumentSubtype,"fieldOptions":_vm.fieldOptions,"mandatoryFieldsMissing":_vm.mandatoryFieldsMissing},on:{"onChange":function($event){return _vm.$emit('onChange', $event)}}})]})]:_c('PADetailCDPTable',{staticClass:"extracted-information-detail__details-values__table",attrs:{"activeLineItemsTabs":_vm.activeLineItemsTabs,"sectionName":_vm.sectionName,"fieldOptions":_vm.fieldOptions,"paginatedRows":_vm.paginatedRows,"pageSize":_vm.pageSize,"currentPage":_vm.currentPage,"showAllPages":_vm.showAllPages},on:{"addRow":function($event){return _vm.$emit('addRow', { currentPage: _vm.currentPage, paginatedRows: _vm.paginatedRows, hasPagination: _vm.hasPagination })},"deleteRow":function($event){return _vm.$emit('deleteRow', $event)}}})],2),(_vm.showPaginationControls)?_c('PaginationControls',{attrs:{"currentPage":_vm.currentPage,"totalPages":_vm.totalPages,"showAllPages":_vm.showAllPages},on:{"updatePage":function($event){_vm.showAllPages = false;
      _vm.currentPage = $event;},"showAllPages":function($event){_vm.showAllPages = true}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }