import { formatUri } from "@/api";
import { HTTP } from "@/lib/http/api";
import { convertPercentageStringToDecimal } from "@/lib/parsers";
import {
  CDP_CHANGELOG_STATES,
  CdpDefaultSkeleton,
  DiscountAndFeeChangesDifference,
  LineItemChangesDifference,
  PERCENTAGE_INPUTS,
  TaxChangesDifference,
} from "@/models/cdp";
import Decimal from "decimal.js";

export default class CDPService {
  static async getCDPDetail(
    companyAppId: string,
    invodiceDocumentId: string
  ): Promise<CdpDefaultSkeleton> {
    try {
      const res = await HTTP.get(
        formatUri`v1/company-apps/${companyAppId}/documents/${invodiceDocumentId}/detail/`
      );

      const parsedDocument = { ...res.data } as CdpDefaultSkeleton;
      parsedDocument.data = res.data.data;

      parsedDocument.data = filterDeletedItemsAndReorderRows(
        parsedDocument.data
      );
      return parsedDocument;
    } catch (error) {
      throw { error: "No data provided" };
    }
  }

  static async updateCDPDocument(
    companyAppId: string,
    invodiceDocumentId: string,
    CDPDocumentUpdated: CdpDefaultSkeleton
  ): Promise<CdpDefaultSkeleton> {
    const documentParsed = {
      data: CDPDocumentUpdated.data,
      comment: CDPDocumentUpdated.comment || "",
      error: CDPDocumentUpdated.error,
      status: CDPDocumentUpdated.status,
    };

    const res = await HTTP.patch(
      formatUri`v1/company-apps/${companyAppId}/documents/${invodiceDocumentId}`,
      documentParsed
    );
    const parsedDocument = { ...res.data } as CdpDefaultSkeleton;

    parsedDocument.data = res.data.data;

    parsedDocument.data = filterDeletedItemsAndReorderRows(parsedDocument.data);
    return parsedDocument;
  }

  static async getCDPsList(
    companyAppId: string,
    params: URLSearchParams = new URLSearchParams(),
    userRole: string
  ) {
    let defaultParams =
      "status=APPROVED&status=MANUAL_REVIEW&status=ERROR&status=DISCARDED";

    if (["STAFF", "OWNER", "ADMIN"].includes(userRole)) {
      defaultParams += "&status=PENDING";
    }

    const paramsString = params.toString()
      ? `?${params.toString()}`
      : `?${defaultParams}`;

    const res = await HTTP.get(
      formatUri`v1/company-apps/${companyAppId}/documents/` + paramsString
    );

    return res.data;
  }

  static async getCDPsListBlockedDocumentsByCurrentUser(
    companyAppId: string,
    blockedDocuments: string
  ) {
    const res = await HTTP.get(
      formatUri`v1/company-apps/${companyAppId}/documents/review/?max_documents=${blockedDocuments}&descending=false`
    );

    return res.data;
  }

  static async getCDPsSync(companyAppId: string, idsList: Array<string>) {
    const documentsIdsList = {
      document_ids_list: idsList,
    };

    const res = await HTTP.post(
      formatUri`v1/company-apps/${companyAppId}/documents/sync/`,
      documentsIdsList
    );

    return res.data;
  }

  static async lockInvoiceDocument(
    companyAppId: string,
    invodiceDocumentId: string
  ) {
    const res = await HTTP.patch(
      formatUri`v1/company-apps/${companyAppId}/documents/${invodiceDocumentId}/lock/`
    );
    return res;
  }

  static async unlockInvoiceDocument(
    companyAppId: string,
    invodiceDocumentId: string
  ) {
    const res = await HTTP.patch(
      formatUri`v1/company-apps/${companyAppId}/documents/${invodiceDocumentId}/unlock/`
    );
    return res;
  }

  static async getCDPVerificationsSummary(
    params: URLSearchParams = new URLSearchParams(),
    companyId: string
  ) {
    const paramsString = !!params.toString() ? "?" + params : "";
    const { data } = await HTTP.get(
      formatUri`dashboard/company-apps/${companyId}/cdp-verifications-summary/` +
        paramsString
    );

    return data;
  }
}

export function parseDataToKeyValueAndConvertPercentages(
  array: any[],
  convertPercentage: boolean = false
): any[] {
  if (!array) return [];

  return array.map((item: any) => {
    const newItem: { [key: string]: any } = {};
    if (convertPercentage) {
      Object.keys(item).forEach((key) => {
        newItem[key] =
          PERCENTAGE_INPUTS.includes(key) && item[key]?.value
            ? convertPercentageStringToDecimal(item[key].value)
            : key && ["id", "page_index"].includes(key)
            ? item[key]
            : item[key]?.value;
      });
    } else {
      if (item.value) {
        Object.keys(item.value).forEach((key) => {
          if (key && ["id", "page_index"].includes(key))
            newItem[key] = item.value[key];
          else newItem[key] = item.value[key].value;
        });

        Object.keys(newItem).forEach((key) => {
          if (PERCENTAGE_INPUTS.includes(key) && newItem[key] != null) {
            const rawValue = newItem[key];

            if (typeof rawValue === "string" && rawValue.includes("%")) {
              const cleanedValue = rawValue.replace(/[^0-9.]/g, "");
              const parsedValue = parseFloat(cleanedValue);
              newItem[key] = !isNaN(parsedValue)
                ? new Decimal(parsedValue).toNumber()
                : 0;
            } else if (!isNaN(parseFloat(rawValue))) {
              newItem[key] = new Decimal(rawValue).toNumber();
            }
          }
        });
      }
    }
    return newItem;
  });
}

function reorderRows(
  arrayToOrder: any[]
): (
  | TaxChangesDifference[]
  | LineItemChangesDifference[]
  | DiscountAndFeeChangesDifference[]
)[] {
  const idNextSet = new Map();
  let lastElement;

  arrayToOrder.forEach((item: any) => {
    if (item.value.id_next?.value)
      idNextSet.set(item.value.id_next.value, item);
    else lastElement = item;
  });

  if (!lastElement) return arrayToOrder;

  const reorderedArray = [] as any[];

  let currentElement: any = lastElement;

  while (currentElement) {
    reorderedArray.unshift(currentElement);
    currentElement = idNextSet.get(currentElement.value.id);
  }
  return reorderedArray;
}

export function filterDeletedItemsAndReorderRows(data: any) {
  const isNotDeleted = (item: any) =>
    item.action !== CDP_CHANGELOG_STATES.DELETE;
  data.tax = reorderRows(data.tax?.filter(isNotDeleted));
  data.line_items = reorderRows(data.line_items?.filter(isNotDeleted));
  data.discounts_and_fees = reorderRows(
    data.discounts_and_fees?.filter(isNotDeleted)
  );

  return data;
}
