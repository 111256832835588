






































import Vue from "vue";
import CompaniesService from "@/api/companies/companiesService";
import { mutationsT } from "@/state/mutations";
import { ActiveVerificationFlow } from "./VerificationFlowSettings.vue";
import eventBus from "@/lib/utils";

export default Vue.extend({
  name: "DeleteVerificationFlowModal",
  props: {
    verificationFlowProps: {
      required: true,
      default: {} as ActiveVerificationFlow,
    },
    showDeleteDetailProp: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      checkIfCanDelete: "",
      responseDeleteError: "",
      loading: false,
    };
  },
  watch: {
    showDeleteDetailProp() {
      this.responseDeleteError = "";
    },
  },
  methods: {
    async checkAndDeleteCompanyApp(companyName: string) {
      if (this.checkIfCanDelete === companyName) {
        try {
          this.loading = true;
          await CompaniesService.deleteCompanyApp(
            this.verificationFlowProps.app_id
          );
        } catch (error: any) {
          this.responseDeleteError =
            "You can't delete a Company App with verifications";
        } finally {
          if (!this.responseDeleteError) {
            const companies = await CompaniesService.getCompanies();
            this.$store.commit(
              mutationsT.UPDATE_COMPANIES,
              companies.data.results
            );

            this.$emit("onDeleteFromVerificationFlowSettings", "settings");
            eventBus.$emit("v-toast", {
              text: this.$t("successful_delete_verification_flow_feedback", {
                verificationFlowName: this.verificationFlowProps.name,
              }),
              type: "success-toast",
            });
            const company = await CompaniesService.getCompany(
              this.$store.state.company.id
            );
            this.$store.commit(mutationsT.UPDATE_COMPANY, company.data);
            this.$emit("onSaved", {
              loading: this.loading,
              verificationFlowProps: this.verificationFlowProps,
            });
            this.loading = false;
            this.checkIfCanDelete = "";

            this.$store.commit(
              "UPDATE_COMPANY_APP_ID",
              this.$store.state.company.company_apps[0].app_id
            );
          }
          this.loading = false;
          this.$emit("closeDeleteDetail", false);
          this.$root.$emit(
            "analytics",
            "CompanySettings - VerificationFlows - Trash - DeleteFlow Clicked"
          );
        }
      } else {
        this.responseDeleteError = "Company App Name not matching";
        this.$emit("closeDeleteDetail", true);
        this.checkIfCanDelete = "";
      }
    },
  },
});
