








































import Vue from "vue";

export default Vue.extend({
  name: "RepresentativeDetails",
  props: {
    managementResponsibilities: {
      required: true,
      type: Array as () => Array<any>,
    },
    jobTitles: {
      required: true,
      type: Array as () => Array<any>,
    },
    principalDetailName: {
      required: true,
      type: String,
    },
  },
  components: {},
  methods: {},
  computed: {},
  data() {
    return {};
  },
});
