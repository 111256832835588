


















































































import KYBProgressTabs from "@/views/kyb/components/KYBProgressTabs.vue";
import Vue from "vue";
import KYBCompanyInfo from "../KYBCompanyInfo.vue";
import KYBExtraDocuments from "../KYBExtraDocuments.vue";
import UBODiagram from "./UBODiagram.vue";
import UserDetail from "@/components/users/UserDetail.vue";
import KYBStatusInfo from "../KYBStatusInfo.vue";
import KYBSanctionsChecks from "../KYBSanctionsChecks.vue";
import KYBAdverseMediaChecks from "../KYBAdverseMediaChecks.vue";
import KYBService from "@/api/kyb/kybService";

export default Vue.extend({
  name: "KYBUBOCompanyDetail",
  components: {
    KYBCompanyInfo,
    UBODiagram,
    KYBProgressTabs,
    KYBExtraDocuments,
    UserDetail,
    KYBStatusInfo,
    KYBSanctionsChecks,
    KYBAdverseMediaChecks,
  },
  props: {
    KYBCompanyDetail: { required: true, type: Object },
    KYBCompanyDetailPrincipals: { required: true },
    countriesList: { required: true, type: Array },
    KYBCompanyReminders: { required: true, type: Array },
    KYBCompanyDetailSanctions: { required: true, type: Object },
    KYBCompanyDetailAdverseMedia: { required: true, type: Object },
    KYBCompanyDetailForms: { required: true, type: Array },
  },
  data() {
    return {
      activeTabName: "companyInfo",
      showUBODetail: false,
      uboDetail: null as any,
      loading: false,
    };
  },
  methods: {
    async getKYBIndividual(userId: string) {
      const user = await KYBService.getKYBIndividual(userId);

      return user.data;
    },
    async getRepresentative() {
      if (this.uboDetail) return;
      try {
        this.loading = true;
        if (!this.KYBCompanyDetail.admin) return;
        this.uboDetail = await this.getKYBIndividual(
          this.KYBCompanyDetail.admin.id
        );
        if (this.uboDetail)
          this.uboDetail.processing_attempts = this.uboDetail.verifications;
        this.showUBODetail = true;
      } finally {
        this.loading = false;
      }
    },
  },
});
