

















































import Vue from "vue";
import RepresentativeDetails from "./components/RepresentativeDetails.vue";
import KYBRepresentativesList from "./KYBRepresentativesList.vue";

export default Vue.extend({
  components: { KYBRepresentativesList, RepresentativeDetails },
  name: "KYBRepresentativeInformation",
  props: {
    KYBCompanyDetailPrincipals: { required: true, type: Array },
    representativeInformation: { required: true, type: Object },
    representativeEmail: { required: true, type: String },
    isScreeningKYBCompany: { required: false, type: Boolean, default: false },
  },
  computed: {},
  data() {
    return {};
  },
  methods: {},
});
