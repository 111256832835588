






















































































































































































import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import KYBRequirementQuestionnaireDeleteModal from "./KYBRequirementQuestionnaireDeleteModal.vue";

export default Vue.extend({
  components: { KYBRequirementQuestionnaireDeleteModal },
  name: "KYBRequirementQuestionnaireQuestionForm",
  watch: {
    localQuestion: {
      handler() {
        if (
          this.localQuestion.type === "MULTISELECT" &&
          !this.choicesMultiSelect.length
        ) {
          this.addChoice();
        }
      },
      deep: true,
    },
    "questionProp.created_at": {
      handler() {
        if (this.questionProp.created_at) this.readable = true;
      },
      immediate: true,
    },
  },
  props: {
    questionProp: { required: true, type: Object },
    create: { required: true, type: Boolean },
  },
  computed: {
    disableSaveChanges() {
      if (!this.localQuestion.text) return true;
      if (
        this.localQuestion.type === "MULTISELECT" &&
        !this.choicesMultiSelect[0].value
      )
        return true;
      return false;
    },
    questionTypeText(): TranslateResult {
      const selectedOption = this.questionnaireOptions.find(
        (option) => option.value === this.localQuestion.type
      );
      return selectedOption ? selectedOption.text : "";
    },
  },
  data() {
    return {
      edit: false,
      localQuestion: JSON.parse(JSON.stringify(this.questionProp)),
      readable: false,
      showDeleteQuestionFormModal: false,
      loading: false,
      questionnaireOptions: [
        {
          value: "TEXT",
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_questionnaire_config_answer_description_type_1"
          ),
        },
        {
          value: "BOOLEAN",
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_questionnaire_config_answer_type_3"
          ),
        },
        {
          value: "NUMBER",
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_questionnaire_config_answer_type_4"
          ),
        },
        {
          value: "DATE",
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_questionnaire_config_answer_type_5"
          ),
        },
        {
          value: "MULTISELECT",
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_questionnaire_config_answer_type_6"
          ),
        },
      ],
      choicesMultiSelect: (this.questionProp?.choices ?? []).map(
        (choice: string) => ({
          value: choice,
        })
      ),
    };
  },
  methods: {
    removeChoice(index: number) {
      this.choicesMultiSelect.splice(index, 1);
    },
    addChoice() {
      this.choicesMultiSelect.push({ value: "" });
    },
    async saveChanges() {
      this.localQuestion.choices = this.choicesMultiSelect.map(
        (choice: { value: string }) => choice.value
      );
      if (!this.create) {
        this.$emit("updateQuestionnaireForm", this.localQuestion);
        this.readable = true;
        return;
      }

      if (this.$store.state.verificationFlow.config.forms_enabled)
        this.$emit("addQuestion", this.localQuestion);
      else this.$emit("updateLocalQuestionnaire", this.localQuestion);
      this.readable = true;
    },
    handleCancel() {
      if (this.create) this.$emit("cancelCreateForm");
      else this.localQuestion = this.questionProp;

      this.readable = true;
    },
  },
});
