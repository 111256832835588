export enum RepresentativeStatus {
  SUCCESS = "completed",
  ERROR = "error",
  MANUAL_SUCCESS = "completed",
  MANUAL_ERROR = "error",
  PENDING = "in_progress",
  MANUAL_REVIEW = "manual_review",
}

export type RepresentativeStatusKeys = keyof typeof RepresentativeStatus;
