


















import Vue from "vue";
import RepresentativeDetails from "./components/RepresentativeDetails.vue";

export default Vue.extend({
  name: "KYBRepresentativesList",
  components: { RepresentativeDetails },
  props: {
    KYBCompanyPrincipals: {
      type: Array as () => Array<any>,
      required: true,
    },
    cmes: {
      type: Array as () => Array<any>,
      required: false,
      default: () => [],
    },
  },
  computed: {
    principalDetails(): any[] {
      return this.KYBCompanyPrincipals?.length
        ? this.KYBCompanyPrincipals
        : this.cmes;
    },
  },
  methods: {
    getPrincipalName(principalDetail: any): string {
      return (
        principalDetail.full_name ||
        principalDetail.output?.fullName ||
        principalDetail.output?.full_name ||
        "-"
      );
    },
    getJobTitles(principalDetail: any): any[] {
      return principalDetail.job_titles?.length
        ? principalDetail.job_titles
        : this.cmes[0]?.output?.jobTitles ||
            this.cmes[0]?.output?.job_titles ||
            [];
    },
    getManagementResponsibilities(principalDetail: any): any[] {
      return principalDetail.management_responsibilities?.length
        ? principalDetail.management_responsibilities
        : this.cmes[0]?.output?.management_responsibilities || [];
    },
  },
});
