























import Vue from "vue";

export default Vue.extend({
  name: "KYBRequirementQuestionnaireDeleteModal",
  props: {
    showDeleteQuestionFormModal: { required: true, type: Boolean },
    question: { required: true, type: Object },
  },
  data() {
    return {};
  },
  methods: {
    onCloseDeleteQuestionFormModal() {
      this.$emit("showDeleteQuestionFormModal", false);
    },
    deleteQuestion() {
      this.$emit("deleteQuestion");
      this.$emit("showDeleteQuestionFormModal", false);
    },
  },
  mounted() {},
});
