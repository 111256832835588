




















































import Vue from "vue";
import { RepresentativeStatusKeys, RepresentativeStatus } from "@/models/kyb";

interface TabsInfo {
  [key: string]: {
    percentage: number;
    status: string;
    steps: { label: string; status: string; showStatusPill?: boolean }[];
  };
}

type Status =
  | "SUCCESS"
  | "MANUAL_REVIEW"
  | "VERIFICATION_ERROR"
  | "ERROR"
  | "PENDING";

export default Vue.extend({
  components: {},
  name: "KYBProgressTabs",
  props: {
    activeTabName: { required: true, type: String },
    KYBCompanyDetail: { required: true, type: Object },
    adminInformation: { required: true, type: Object },
    KYBCompanyDetailSanctions: { required: true, type: Object },
    KYBCompanyDetailAdverseMedia: { required: true, type: Object },
    KYBCompanyDetailForms: { required: true, type: Array },
  },
  watch: {
    "KYBCompanyDetail.admin.id"() {
      if (this.KYBCompanyDetail.admin?.id)
        this.$emit("showAndGetRepresentativeDetail");
    },
  },
  computed: {
    representativeCmesInformation(): any {
      if (!this.adminInformation) return null;

      const getStatus = (key: string | string[]): string =>
        ["SUCCESS", "MANUAL_SUCCESS"].includes(this.adminInformation.status)
          ? "SUCCESS"
          : this.processRepresentativeCmes(
              this.adminInformation?.processing_attempts,
              key
            ) || "BLOCKED";

      return {
        kycPA: getStatus([
          "PASSPORT",
          "DRIVING_LICENSE",
          "NATIONAL_ID",
          "UPOA",
        ]),
        representativePA: getStatus("REPRESENTATIVE"),
      };
    },
    progressTabsInformation() {
      const tabsInfo = {
        companyInfo: {
          percentage: 0,
          status: "in_progress",
          steps: [
            { label: "progress_tab_company_details", status: "IN_PROGRESS" },
          ],
        },
        extraDocuments: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_document_uploaded", status: "BLOCKED" },
            { label: "progress_tab_legal_acceptance", status: "BLOCKED" },
          ],
        },
        UBODiagram: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_ubo_list", status: "BLOCKED" },
            { label: "progress_tab_legal_acceptance", status: "BLOCKED" },
          ] as Array<{
            label: string;
            status: string;
            showStatusPill?: boolean;
          }>,
        },
        representative: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_representative_mail", status: "BLOCKED" },
            {
              label: "progress_tab_representative_kyc",
              status: "BLOCKED",
              showStatusPill: true,
            },
            {
              label: "progress_tab_representative_match",
              status: "BLOCKED",
              showStatusPill: true,
            },
            {
              label: "progress_tab_legal_acceptance",
              status: "BLOCKED",
            },
          ],
        },
        sanctionsChecks: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_sanctions_checked", status: "BLOCKED" },
            {
              label: "progress_tab_sanctions_analysis",
              status: "BLOCKED",
              showStatusPill: true,
            },
          ],
        },
        adverseMediaChecks: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_adverse_media_checked", status: "BLOCKED" },
            {
              label: "progress_tab_adverse_media_analysis",
              status: "BLOCKED",
              showStatusPill: true,
            },
          ],
        },
        proofOfAddress: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_document_uploaded", status: "BLOCKED" },
            {
              label: "progress_tab_poa_match",
              status: "BLOCKED",
              showStatusPill: true,
            },
          ],
        },
        forms: {
          percentage: 0,
          status: "blocked",
          steps: [
            { label: "progress_tab_questionnaire_answered", status: "BLOCKED" },
            {
              label: "progress_tab_legal_acceptance",
              status: "BLOCKED",
            },
          ],
        },
      };
      const flowStats = this.KYBCompanyDetail?.flow_stats;
      const sanctions = this.KYBCompanyDetailSanctions ?? null;
      const adverseMedia = this.KYBCompanyDetailAdverseMedia ?? null;
      const forms = this.KYBCompanyDetailForms ?? null;
      const uboVerificationEnabled =
        this.$store.state.verificationFlow?.config?.ubo_verification_enabled;

      const CPOAProcessingAttempts =
        this.KYBCompanyDetail?.processing_attempts?.filter(
          (processingAttempt: any) =>
            processingAttempt.permission_type === "CPOA"
        );

      if (!flowStats) return tabsInfo;

      if (uboVerificationEnabled)
        tabsInfo.UBODiagram.steps.push({
          label: "progress_tab_ubo_statuses",
          status: "BLOCKED",
          showStatusPill: true,
        });

      this.updateCompanyInfoTab(flowStats, tabsInfo);
      this.updateExtraDocumentsAndCPOATab(tabsInfo, CPOAProcessingAttempts);
      this.updateFormsTab(tabsInfo, forms);
      this.updateUBODiagramTab(flowStats, tabsInfo);
      this.updateRepresentativeTab(tabsInfo);
      this.updateSanctionsAndAdverseMediaChecksTab(
        "sanctionsChecks",
        sanctions,
        tabsInfo
      );
      this.updateSanctionsAndAdverseMediaChecksTab(
        "adverseMediaChecks",
        adverseMedia,
        tabsInfo
      );

      if (this.KYBCompanyDetail?.legal_acceptance_date) {
        tabsInfo.extraDocuments.percentage = 100;
        tabsInfo.extraDocuments.status = "completed";
        tabsInfo.extraDocuments.steps[1].status = "SUCCESS";

        tabsInfo.forms.percentage = 100;
        tabsInfo.forms.status = "completed";
        tabsInfo.forms.steps[1].status = "SUCCESS";
      }

      return tabsInfo;
    },
    progressTabs() {
      const tabs: Array<any> = [];

      const uboDiscovery =
        this.$store.state.verificationFlow?.config?.ubo_discovery_enabled;

      const companyProofOfAddress =
        this.$store.state.verificationFlow?.permissions?.some(
          (permissionsGroup: Array<string>) => permissionsGroup.includes("CPOA")
        );

      const sanctionsChecks =
        this.$store.state.verificationFlow?.config?.sanctions_screening_enabled;

      const adverseMediaChecks =
        this.$store.state.verificationFlow?.config
          ?.adverse_media_screening_enabled;

      const formsEnabled =
        this.$store.state.verificationFlow?.config?.forms_enabled;

      tabs.push({
        activeTabName: "companyInfo",
        status: this.progressTabsInformation.companyInfo.status,
        percentage: this.progressTabsInformation.companyInfo.percentage,
        label: "companies_detail_company_info",
        steps: this.progressTabsInformation.companyInfo.steps,
      });

      if (
        (this.$store.state.verificationFlow &&
          this.$store.state.verificationFlow.extra_documents &&
          this.$store.state.verificationFlow.extra_documents.length) ||
        (this.KYBCompanyDetail &&
          this.KYBCompanyDetail.files &&
          this.KYBCompanyDetail.files.length)
      )
        tabs.push({
          activeTabName: "companyExtraDocuments",
          status: this.progressTabsInformation.extraDocuments.status,
          percentage: this.progressTabsInformation.extraDocuments
            ? this.progressTabsInformation.extraDocuments.percentage
            : 0,
          label: "companies_detail_files",
          steps: this.progressTabsInformation.extraDocuments.steps,
        });

      tabs.push({
        activeTabName: "representativeDetail",
        status: this.progressTabsInformation.representative
          ? this.progressTabsInformation.representative.status
          : "manual_review",
        percentage: this.progressTabsInformation.representative
          ? this.progressTabsInformation.representative.percentage
          : 0,
        label: "companies_detail_representative",
        steps: this.progressTabsInformation.representative.steps,
      });
      if (formsEnabled) {
        tabs.push({
          activeTabName: "forms",
          status: this.progressTabsInformation.forms.status,
          percentage: this.progressTabsInformation.forms
            ? this.progressTabsInformation.forms.percentage
            : 0,
          label: "progress_tab_questionnaires",
          steps: this.progressTabsInformation.forms.steps,
        });
      }
      if (companyProofOfAddress)
        tabs.push({
          activeTabName: "proofOfAddress",
          status: this.progressTabsInformation.proofOfAddress
            ? this.progressTabsInformation.proofOfAddress.status
            : "manual_review",
          percentage: this.progressTabsInformation.proofOfAddress
            ? this.progressTabsInformation.proofOfAddress.percentage
            : 0,
          label: "proof_of_address",
          steps: this.progressTabsInformation.proofOfAddress.steps,
        });
      if (uboDiscovery)
        tabs.push({
          activeTabName: "UBODiagram",
          status: this.progressTabsInformation.UBODiagram
            ? this.progressTabsInformation.UBODiagram.status
            : "manual_review",
          percentage: this.progressTabsInformation.UBODiagram
            ? this.progressTabsInformation.UBODiagram.percentage
            : 0,
          label: "companies_detail_ubo_diagram",
          steps: this.progressTabsInformation.UBODiagram.steps,
        });

      if (sanctionsChecks)
        tabs.push({
          activeTabName: "sanctionsChecks",
          status: this.progressTabsInformation.sanctionsChecks
            ? this.progressTabsInformation.sanctionsChecks.status
            : "manual_review",
          percentage: this.progressTabsInformation.sanctionsChecks
            ? this.progressTabsInformation.sanctionsChecks.percentage
            : 0,
          label:
            "verification_flow_settings_verification_requirements_kyb_checks_switch",
          steps: this.progressTabsInformation.sanctionsChecks.steps,
        });

      if (adverseMediaChecks)
        tabs.push({
          activeTabName: "adverseMediaChecks",
          status: this.progressTabsInformation.adverseMediaChecks
            ? this.progressTabsInformation.adverseMediaChecks.status
            : "manual_review",
          percentage: this.progressTabsInformation.adverseMediaChecks
            ? this.progressTabsInformation.adverseMediaChecks.percentage
            : 0,
          label: "progress_tab_adverse_media",
          steps: this.progressTabsInformation.adverseMediaChecks.steps,
        });

      return tabs;
    },
  },
  methods: {
    showAndGetRepresentativeDetail(event: string) {
      this.$emit("activeTabName", event);
      this.$emit("showAndGetRepresentativeDetail");
    },
    processRepresentativeCmes(
      processingAttempts: Array<any>,
      key: Array<string> | string
    ) {
      if (!processingAttempts?.length) return "BLOCKED";
      const keys = Array.isArray(key) ? key : [key];
      for (const processingAttempt of processingAttempts) {
        if (keys.includes(processingAttempt.permission_type))
          return processingAttempt.status;
      }
    },
    getStatusClassIcon(status: string) {
      //TODO: Move function at lib.ts
      return {
        "fa fa-check-circle":
          status === "SUCCESS" || status === "MANUAL_SUCCESS",
        "fa fa-times-circle": status === "MANUAL_ERROR" || status === "ERROR",
        "fa fa-question-circle":
          status === "PENDING" ||
          status === "MANUAL_REVIEW" ||
          status === "IN_PROGRESS" ||
          status === "BLOCKED",
        [status === "IN_PROGRESS" ? "IN_PROGRESS" : `status_${status}`]: true,
      };
    },
    updateCompanyInfoTab(flowStats: any, tabsInfo: TabsInfo) {
      if (flowStats.is_company_set) {
        tabsInfo.companyInfo.percentage = 50;
        if (this.KYBCompanyDetail.details?.active) {
          tabsInfo.companyInfo.percentage = 100;
          tabsInfo.companyInfo.status = "completed";
          tabsInfo.companyInfo.steps[0].status = "SUCCESS";
          return;
        }
        tabsInfo.companyInfo.percentage = 50;
        tabsInfo.companyInfo.status = "error";
        tabsInfo.companyInfo.steps[0].status = "SUCCESS";
      }
    },
    updateFormsTab(tabsInfo: TabsInfo, forms: Array<any>) {
      if (
        forms &&
        forms
          .filter((question: any) => question.mandatory)
          .every((question: any) => question.answers.length > 0)
      ) {
        tabsInfo.forms.percentage = 50;
        tabsInfo.forms.status = "in_progress";
        tabsInfo.forms.steps[0].status = "SUCCESS";
      }
    },
    updateExtraDocumentsAndCPOATab(
      tabsInfo: TabsInfo,
      CPOAProcessingAttempts: Array<any>
    ) {
      if (this.KYBCompanyDetail.files && this.KYBCompanyDetail.files.length) {
        tabsInfo.extraDocuments.percentage = 50;
        tabsInfo.extraDocuments.status = "in_progress";
        tabsInfo.extraDocuments.steps[0].status = "SUCCESS";
      }

      if (CPOAProcessingAttempts && CPOAProcessingAttempts.length) {
        const priorities = [
          "SUCCESS",
          "MANUAL_REVIEW",
          "VERIFICATION_ERROR",
          "ERROR",
        ];
        tabsInfo.proofOfAddress.steps[0].status = "SUCCESS";
        tabsInfo.proofOfAddress.percentage = 50;
        tabsInfo.proofOfAddress.status = "in_progress";

        let finalStatus = CPOAProcessingAttempts[0].status;
        let manualReviewStatus = CPOAProcessingAttempts[0].manual_review_status;

        for (const attempt of CPOAProcessingAttempts) {
          if (
            priorities.indexOf(attempt.status) !== -1 &&
            priorities.indexOf(attempt.status) < priorities.indexOf(finalStatus)
          ) {
            finalStatus = attempt.status;
            manualReviewStatus = attempt.manual_review_status;
          }
        }

        if (finalStatus) {
          tabsInfo.proofOfAddress.percentage = 50;
          tabsInfo.proofOfAddress.steps[1].status = finalStatus as Status;

          const statusMap: Record<
            Status,
            Partial<typeof tabsInfo.proofOfAddress>
          > = {
            SUCCESS: { percentage: 100, status: "completed" },
            MANUAL_REVIEW: { status: "manual_review" },
            VERIFICATION_ERROR: { status: "manual_review" },
            ERROR: { status: "manual_review" },
            PENDING: { status: "pending" },
          };

          Object.assign(
            tabsInfo.proofOfAddress,
            statusMap[finalStatus as Status]
          );
        }

        if (manualReviewStatus) {
          tabsInfo.proofOfAddress.percentage = 100;
          tabsInfo.proofOfAddress.status =
            manualReviewStatus === "SUCCESS"
              ? "completed"
              : manualReviewStatus.toLowerCase();
        }
      }
    },
    updateUBODiagramTab(flowStats: any, tabsInfo: TabsInfo) {
      const { ubo_list, legal_acceptance_date } = this.KYBCompanyDetail || {};
      const uboVerificationEnabled =
        this.$store.state.verificationFlow?.config?.ubo_verification_enabled;

      if (flowStats.is_ubo_list_set) {
        tabsInfo.UBODiagram.status = "in_progress";
        tabsInfo.UBODiagram.steps[0].status = "SUCCESS";
      }

      if (!uboVerificationEnabled) {
        tabsInfo.UBODiagram.percentage = flowStats.is_ubo_list_set ? 50 : 0;

        if (legal_acceptance_date) {
          tabsInfo.UBODiagram.percentage = 100;
          tabsInfo.UBODiagram.steps[1].status = "SUCCESS";
          tabsInfo.UBODiagram.status = "completed";
        }
        return;
      }
      if (flowStats.is_ubo_list_set) {
        tabsInfo.UBODiagram.percentage = 33;

        if (ubo_list?.length) {
          if (!legal_acceptance_date) {
            tabsInfo.UBODiagram.steps[2].status = "BLOCKED";
            return;
          }

          tabsInfo.UBODiagram.percentage = 66;
          tabsInfo.UBODiagram.steps[1].status = "SUCCESS";
          tabsInfo.UBODiagram.status = "in_progress";

          const { status, percentage } = this.updateUBOStatusPriority(ubo_list);

          tabsInfo.UBODiagram.status = status;
          tabsInfo.UBODiagram.percentage = percentage || 66;
          tabsInfo.UBODiagram.steps[2].status =
            status === "completed" ? "SUCCESS" : status.toUpperCase();

          const hasIgnoredUboWithLegalAcceptance = ubo_list.some(
            (ubo: any) => ubo.ignored && legal_acceptance_date
          );

          if (hasIgnoredUboWithLegalAcceptance) {
            tabsInfo.UBODiagram.status = "completed";
            tabsInfo.UBODiagram.percentage = 100;
            tabsInfo.UBODiagram.steps[2].status = "SUCCESS";
          }
        }
      }
    },
    updateUBOStatusPriority(uboList: any[]): {
      status: string;
      percentage?: number;
    } {
      const priorities = [
        "ERROR",
        "MANUAL_ERROR",
        "MANUAL_REVIEW",
        "PENDING",
        "SUCCESS",
        "MANUAL_SUCCESS",
        "BLOCKED",
      ];

      let lastStatus = "BLOCKED";
      let lastStatusIndex = priorities.indexOf(lastStatus);

      for (const ubo of uboList) {
        if (ubo.status && priorities.includes(ubo.status)) {
          const currentStatusIndex = priorities.indexOf(ubo.status);

          if (currentStatusIndex < lastStatusIndex) {
            lastStatus = ubo.status;
            lastStatusIndex = currentStatusIndex;
          }
        }
      }

      const statusMap: Record<string, { status: string; percentage?: number }> =
        {
          SUCCESS: { status: "completed", percentage: 100 },
          MANUAL_SUCCESS: { status: "completed", percentage: 100 },
          MANUAL_REVIEW: { status: "manual_review" },
          ERROR: { status: "error" },
          MANUAL_ERROR: { status: "error" },
          PENDING: { status: "in_progress" },
          BLOCKED: { status: "blocked" },
        };

      return statusMap[lastStatus];
    },
    updateRepresentativeTab(tabsInfo: TabsInfo) {
      if (this.KYBCompanyDetail.admin_email) {
        tabsInfo.representative.percentage = 25;
        tabsInfo.representative.status = "in_progress";
        tabsInfo.representative.steps[0].status = "SUCCESS";
        if (this.representativeCmesInformation) {
          const { kycPA, representativePA } =
            this.representativeCmesInformation;
          if (kycPA !== "BLOCKED") tabsInfo.representative.percentage = 50;

          tabsInfo.representative.steps[1].status = kycPA;
          tabsInfo.representative.steps[2].status = representativePA;

          if (representativePA !== "BLOCKED") {
            tabsInfo.representative.percentage = 75;
          }
        }
        if (this.KYBCompanyDetail?.legal_acceptance_date) {
          tabsInfo.representative.steps[3].status = "SUCCESS";
          tabsInfo.representative.status =
            RepresentativeStatus[
              this.adminInformation?.status as RepresentativeStatusKeys
            ]?.toLowerCase() || "blocked";
          tabsInfo.representative.percentage = 100;
        }
      }
    },
    updateSanctionsAndAdverseMediaChecksTab(
      checksKey: "sanctionsChecks" | "adverseMediaChecks",
      checksObj: any,
      tabsInfo: TabsInfo
    ) {
      if (Object.keys(checksObj).length) {
        tabsInfo[checksKey].percentage = 33;
        tabsInfo[checksKey].status = "in_progress";
        tabsInfo[checksKey].steps[0].status = "IN_PROGRESS";

        if (checksObj.has_hits) {
          tabsInfo[checksKey].percentage = 50;
          tabsInfo[checksKey].status = "manual_review";
          tabsInfo[checksKey].steps[1].status = "MANUAL_REVIEW";
        }

        if (["IGNORED", "CONFIRMED"].includes(checksObj.status)) {
          tabsInfo[checksKey].percentage = 100;
          tabsInfo[checksKey].status =
            checksObj.status === "IGNORED" ? "completed" : "error";
          tabsInfo[checksKey].steps[0].status = "SUCCESS";
          tabsInfo[checksKey].steps[1].status =
            checksObj.status === "IGNORED" ? "SUCCESS" : "ERROR";
        }

        if (checksObj.status === "CONFIRMED" && !checksObj.has_hits) {
          tabsInfo[checksKey].status = "completed";
          tabsInfo[checksKey].steps[1].status = "SUCCESS";
        }
      }
    },
  },
});
