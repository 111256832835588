













































import Vue from "vue";
import KYBFormsDetailAnswer from "./components/KYBFormsDetailAnswer.vue";

interface User {
  id: string;
  email: string;
  first_name?: string;
  last_name?: string;
}

interface Answer {
  id: string;
  created_at: Date;
  raw_answer?: string;
  answered_by: User;
}

interface Question {
  id: string;
  text: string;
  mandatory: boolean;
  answers?: Answer[];
  choices?: Array<string> | null;
  type: string;
}

export default Vue.extend({
  name: "KYBFormsDetail",
  components: {
    KYBFormsDetailAnswer,
  },
  props: {
    questions: { type: Array as () => Question[], required: true },
    kybCompanyDetail: { type: Object, required: true },
  },
  data() {
    return {
      showPreviousAnswers: false,
    };
  },
  methods: {
    hasAnswers(question: Question): boolean {
      return Array.isArray(question.answers) && question.answers.length > 0;
    },
    hasMoreAnswers(question: Question): boolean {
      return this.safeAnswers(question).length > 1;
    },
    safeAnswers(question: Question): Answer[] {
      return Array.isArray(question.answers) ? question.answers : [];
    },
    togglePreviousAnswers() {
      this.showPreviousAnswers = !this.showPreviousAnswers;
    },
  },
});
