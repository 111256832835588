import { v4 as uuidv4 } from "uuid";

export const PERCENTAGE_INPUTS = [
  "discount_percent",
  "iva_percent",
  "re_percent",
  "discount_percent",
  "fees_percent",
  "tax_percent",
];

export const CDP_CHANGELOG_STATES = {
  NONE: "NONE",
  ADD: "ADD",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
};

export interface OwnerUser {
  first_name: string;
  email: string;
  id: string;
}

export interface Invoice {
  created_at: string;
  document_type: string;
  invoice_receiver_name: string | null;
  invoice_status: string;
  invoice_supplier_name: string;
  manual_review_status: string | null;
  owner_user: OwnerUser;
  permission_type: string;
  status: string;
  type: string;
  updated_at: string;
  id: string;
}

export const CDP_DEFAULT_SKELETON = {
  comment: "",
  created_at: "",
  is_original_document: true,
  id: "CDP_DEFAULT_SKELETON",
  revisions: [
    {
      id: "CDP_DEFAULT_SKELETON",
    },
  ],
  locked_by: null,
  data: {
    customer_number: { value: "" },
    discounts_and_fees: [
      {
        id: uuidv4(),
        id_next: { value: null },
        base_amount: { value: "", oldValue: "", key: "base_amount" },
        discount_percent: { value: "", oldValue: "", key: "discount_percent" },
        discount_amount: { value: "", oldValue: "", key: "discount_amount" },
        fees_percent: { value: "", oldValue: "", key: "fees_percent" },
        fees_amount: { value: "", oldValue: "", key: "fees_amount" },
        total: { value: "", oldValue: "", key: "total" },
      },
    ],
    document_category: { value: "" },
    document_issue_date: { value: "" },
    document_number: { value: "" },
    document_series_number: { value: "" },
    document_subtype: { value: "" },
    document_type: { value: "" },
    line_items: [
      {
        id: uuidv4(),
        id_next: { value: null },
        bill_of_landing_reference: {
          value: "",
          oldValue: "",
          key: "bill_of_landing_reference",
        },
        identifier: { value: "", oldValue: "", key: "identifier" },
        uom_description: { value: "", oldValue: "", key: "uom_description" },
        units: { value: "", oldValue: "", key: "units" },
        package: { value: "", oldValue: "", key: "package" },
        total: { value: "", oldValue: "", key: "total" },
        unit_gross_amount: {
          value: "",
          oldValue: "",
          key: "unit_gross_amount",
        },
        unit_discount_amount: {
          value: "",
          oldValue: "",
          key: "unit_discount_amount",
        },
        unit_green_dot_amount: {
          value: "",
          oldValue: "",
          key: "unit_green_dot_amount",
        },
        unit_net_amount: { value: "", oldValue: "", key: "unit_net_amount" },
        unit_total_price: { value: "", oldValue: "", key: "unit_total_price" },
        gross_amount: { value: "", oldValue: "", key: "gross_amount" },
        discount_percent: { value: "", oldValue: "", key: "discount_percent" },
        discount_amount: { value: "", oldValue: "", key: "discount_amount" },
        green_dot: { value: "", oldValue: "", key: "green_dot" },
        ibee: { value: "", oldValue: "", key: "ibee" },
        fees_percent: { value: "", oldValue: "", key: "fees_percent" },
        fees_amount: { value: "", oldValue: "", key: "fees_amount" },
        tax_base_amount: { value: "", oldValue: "", key: "tax_base_amount" },
        tax_percent: { value: "", oldValue: "", key: "tax_percent" },
        tax_amount: { value: "", oldValue: "", key: "tax_amount" },
        total_amount: { value: "", oldValue: "", key: "total_amount" },
        product_description: {
          value: "",
          oldValue: "",
          key: "product_description",
        },
        product_name: { value: "", oldValue: "", key: "product_name" },
        uom: { value: "", oldValue: "", key: "uom" },
      },
    ],
    payment_currency: { value: "" },
    payment_due_amount: { value: "" },
    payment_due_date: { value: "" },
    payment_method: { value: "" },
    payment_status: { value: "" },
    receiver_name: { value: "" },
    receiver_phone: { value: "" },
    supplier_name: { value: "" },
    supplier_vat_code: { value: "" },
    tax: [
      {
        id: uuidv4(),
        id_next: { value: null },
        base_amount: { value: "", oldValue: "", key: "base_amount" },
        iva_percent: { value: "", oldValue: "", key: "iva_percent" },
        iva_amount: { value: "", oldValue: "", key: "iva_amount" },
        re_percent: { value: "", oldValue: "", key: "re_percent" },
        re_amount: { value: "", oldValue: "", key: "re_amount" },
        total: { value: "", oldValue: "", key: "total" },
      },
    ],
    total_additional_cost: { value: "" },
    total_cost: { value: "" },
    total_discount_amount: { value: "" },
    total_fees_amount: { value: "" },
    total_green_dot: { value: "" },
    total_gross_amount: { value: "" },
    total_ibee: { value: "" },
    total_irpf: { value: "" },
    total_net_amount: { value: "" },
    total_tax_amount: { value: "" },
    vendor_address: { value: "" },
    vendor_phone: { value: "" },
    vendor_url: { value: "" },
  },
};

export interface DiscountAndFeeChangesDifference {
  id: string;
  id_next: { value: string | null };
  base_amount: { value: string; oldValue: string; confidence: string };
  discount_amount: { value: string; oldValue: string; confidence: string };
  discount_percent: { value: string; oldValue: string; confidence: string };
  fees_amount: { value: string; oldValue: string; confidence: string };
  fees_percent: { value: string; oldValue: string; confidence: string };
  total: { value: string; oldValue: string; confidence: string };
}

export interface LineItemChangesDifference {
  id: string;
  id_next: { value: string | null };
  bill_of_landing_reference: {
    value: string;
    oldValue: string;
    confidence: string;
  };
  discount_amount: { value: string; oldValue: string; confidence: string };
  discount_percent: { value: string; oldValue: string; confidence: string };
  fees_amount: { value: string; oldValue: string; confidence: string };
  fees_percent: { value: string; oldValue: string; confidence: string };
  green_dot: { value: string; oldValue: string; confidence: string };
  gross_amount: { value: string; oldValue: string; confidence: string };
  ibee: { value: string; oldValue: string; confidence: string };
  identifier: { value: string; oldValue: string; confidence: string };
  package: { value: string; oldValue: string; confidence: string };
  product_description: { value: string; oldValue: string; confidence: string };
  product_name: { value: string; oldValue: string; confidence: string };
  tax_amount: { value: string; oldValue: string; confidence: string };
  tax_base_amount: { value: string; oldValue: string; confidence: string };
  tax_percent: { value: string; oldValue: string; confidence: string };
  total: { value: string; oldValue: string; confidence: string };
  total_amount: { value: string; oldValue: string; confidence: string };
  unit_discount_amount: { value: string; oldValue: string; confidence: string };
  unit_green_dot_amount: {
    value: string;
    oldValue: string;
    confidence: string;
  };
  unit_gross_amount: { value: string; oldValue: string; confidence: string };
  unit_net_amount: { value: string; oldValue: string; confidence: string };
  unit_total_price: { value: string; oldValue: string; confidence: string };
  units: { value: string; oldValue: string; confidence: string };
  uom: { value: string; oldValue: string; confidence: string };
  uom_description: { value: string; oldValue: string; confidence: string };
}

export interface TaxChangesDifference {
  id: string;
  id_next: { value: string | null };
  base_amount: { value: string; oldValue: string; confidence: string };
  iva_amount: { value: string; oldValue: string; confidence: string };
  iva_percent: { value: string; oldValue: string; confidence: string };
  re_amount: { value: string; oldValue: string; confidence: string };
  re_percent: { value: string; oldValue: string; confidence: string };
  total: { value: string; oldValue: string; confidence: string };
}

interface ILockedBy {
  first_name: string;
  last_name: string;
  email: string;
  id: string;
}

export type LockedBy = ILockedBy | null;

export interface CdpDefaultSkeleton {
  comment: string;
  created_at: string;
  status: string;
  original_document_id: string;
  is_original_document: boolean;
  error: string;
  id: string;
  revisions: Array<{ is_current: boolean; id: string; status: string }>;
  reviewer_user: {
    first_name: string;
    last_name: string;
    email: string;
  } | null;
  updated_at: string;
  locked_by: LockedBy;
  data: {
    customer_number: { value: string; oldValue: string; confidence: string };
    discounts_and_fees: DiscountAndFeeChangesDifference[];
    document_category: { value: string; oldValue: string; confidence: string };
    document_issue_date: {
      value: string;
      oldValue: string;
      confidence: string;
    };
    document_number: { value: string; oldValue: string; confidence: string };
    document_series_number: {
      value: string;
      oldValue: string;
      confidence: string;
    };
    document_subtype: { value: string; oldValue: string; confidence: string };
    document_type: { value: string; oldValue: string; confidence: string };
    line_items: LineItemChangesDifference[];
    payment_currency: { value: string; oldValue: string; confidence: string };
    payment_due_amount: { value: string; oldValue: string; confidence: string };
    payment_due_date: { value: string; oldValue: string; confidence: string };
    payment_method: { value: string; oldValue: string; confidence: string };
    payment_status: { value: string; oldValue: string; confidence: string };
    receiver_name: { value: string; oldValue: string; confidence: string };
    receiver_phone: { value: string; oldValue: string; confidence: string };
    supplier_name: { value: string; oldValue: string; confidence: string };
    supplier_vat_code: { value: string; oldValue: string; confidence: string };
    tax: TaxChangesDifference[];
    total_additional_cost: {
      value: string;
      oldValue: string;
      confidence: string;
    };
    total_cost: { value: string; oldValue: string; confidence: string };
    total_discount_amount: {
      value: string;
      oldValue: string;
      confidence: string;
    };
    total_fees_amount: { value: string; oldValue: string; confidence: string };
    total_green_dot: { value: string; oldValue: string; confidence: string };
    total_gross_amount: { value: string; oldValue: string; confidence: string };
    total_ibee: { value: string; oldValue: string; confidence: string };
    total_irpf: { value: string; oldValue: string; confidence: string };
    total_net_amount: { value: string; oldValue: string; confidence: string };
    total_tax_amount: { value: string; oldValue: string; confidence: string };
    vendor_address: { value: string; oldValue: string; confidence: string };
    vendor_phone: { value: string; oldValue: string; confidence: string };
    vendor_url: { value: string; oldValue: string; confidence: string };
  };
  raw_data: {
    confidence: number;
  };
  confidence: number;
  processing_status: string;
}
