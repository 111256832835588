import { formatUri } from "@/api";
import { HTTP } from "@/lib/http/api";

export default class KYBService {
  static async createKYBCompany(
    companyAppId: string,
    contactEmail: string | null
  ) {
    const res = await HTTP.post(formatUri`v1/kyb/companies/`, {
      company_app_id: companyAppId,
      ...(contactEmail ? { contact_email: contactEmail } : {}),
    });
    return res;
  }

  static async searchCompanyInfo(
    companyName: string,
    companyCountry: string,
    companyKYBId: string
  ) {
    const params = new URLSearchParams();
    params.append("company_name", companyName);
    params.append("company_country", companyCountry);
    params.append("kyb_company_id", companyKYBId);

    const res = await HTTP.get(formatUri`v1/kyb/companies/search/?` + params);
    return res;
  }

  static async patchKYBCompany(KYBCompanyId: string, data: any) {
    const res = await HTTP.patch(
      formatUri`v1/kyb/companies/${KYBCompanyId}/`,
      data
    );
    return res;
  }

  static async getKYBCompany(KYBCompanyId: string) {
    const res = await HTTP.get(formatUri`v1/kyb/companies/${KYBCompanyId}/`);
    return res;
  }

  static async getKYBCompanies(
    companyAppId: string,
    params: URLSearchParams = new URLSearchParams()
  ) {
    const paramsString = !!params.toString() ? "?" + params : "";

    const res = await HTTP.get(
      formatUri`v1/company-apps/${companyAppId}/kyb/companies/` + paramsString
    );

    return res.data;
  }

  static async ignoreKYBCompany(KYBCompanyId: string) {
    const res = await HTTP.patch(
      formatUri`v1/kyb/companies/${KYBCompanyId}/ignore`
    );
    return res;
  }

  static async ignoreKYBIndividual(KYBIndividualId: string) {
    const res = await HTTP.patch(
      formatUri`v1/kyb/individuals/${KYBIndividualId}/ignore`
    );
    return res;
  }

  static async postKYBCompanyExtraDocuments(KYBCompanyId: string, data: any) {
    const res = await HTTP.post(
      formatUri`v1/kyb/companies/${KYBCompanyId}/files`,
      data
    );
    return res;
  }

  static async getKYBIndividual(userId: string) {
    const res = await HTTP.get(formatUri`v1/kyb/individuals/${userId}/`);
    return res;
  }

  static async getKYBCompanySanctions(kybCompanyId: string) {
    const res = await HTTP.get(
      formatUri`v1/kyb/companies/${kybCompanyId}/sanctions`
    );
    return res;
  }

  static async ignoreOrConfirmSanction(
    KYBCompanyId: string,
    sanctionId: string,
    action: string
  ) {
    const res = await HTTP.patch(
      formatUri`v1/kyb/companies/${KYBCompanyId}/sanctions/${sanctionId}/${action}`
    );
    return res;
  }

  static async getCompanyVerificationsSummary(
    params: URLSearchParams = new URLSearchParams(),
    companyId: string
  ) {
    const paramsString = !!params.toString() ? "?" + params : "";
    const { data } = await HTTP.get(
      formatUri`dashboard/company-apps/${companyId}/kybcompany-verifications-summary/` +
        paramsString
    );

    return data;
  }

  static async getVerifiedCompanies(
    params: URLSearchParams = new URLSearchParams(),
    companyId: string
  ) {
    const paramsString = !!params.toString() ? "?" + params : "";
    const { data } = await HTTP.get(
      formatUri`dashboard/company-apps/${companyId}/kybcompany-verifications/` +
        paramsString
    );

    return data;
  }

  static async setContact(companyAppId: string, contactEmail: string) {
    const res = await HTTP.post(
      formatUri`v1/kyb/companies/${companyAppId}/set-contact`,
      {
        contact_email: contactEmail,
      }
    );
    return res;
  }

  static async deleteEmptyKYBCompany(kybCompanyId: string) {
    const res = await HTTP.delete(
      formatUri`v1/kyb/companies/${kybCompanyId}/delete`
    );
    return res;
  }

  static async getKYBCompanyAdverseMedia(kybCompanyId: string) {
    const res = await HTTP.get(
      formatUri`v1/kyb/companies/${kybCompanyId}/media`
    );
    return res;
  }

  static async ignoreOrConfirmAdverseMedia(
    KYBCompanyId: string,
    mediaId: string,
    action: string
  ) {
    const res = await HTTP.patch(
      formatUri`v1/kyb/companies/${KYBCompanyId}/media/${mediaId}/${action}`
    );
    return res;
  }

  static async sendKYBIndividualEmailReminderButton(kybIndividualId: string) {
    const res = await HTTP.post(
      formatUri`v1/kyb/individuals/${kybIndividualId}/remind`
    );
    return res;
  }

  static async getKYBCompanyReminders(kybCompanyId: string) {
    const res = await HTTP.get(
      formatUri`v1/kyb/companies/${kybCompanyId}/get-reminders`
    );
    return res;
  }

  static async generateKYBIndividualTempToken(
    kybCompanyId: string,
    individualId: string
  ) {
    const res = await HTTP.post(
      formatUri`v1/kyb/companies/${kybCompanyId}/temporary-token`,
      {
        kyb_individual_id: individualId,
        expiration_days: 7,
      }
    );
    return res;
  }

  static async getKYBCompanyForms(kybCompanyId: string) {
    const res = await HTTP.get(
      formatUri`v1/kyb/companies/${kybCompanyId}/forms`
    );
    return res;
  }

  static async getKYBCompanyFormId(kybCompanyId: string, formId: string) {
    const res = await HTTP.get(
      formatUri`v1/kyb/companies/${kybCompanyId}/forms/${formId}`
    );
    return res;
  }
}
