<template>
  <div slot="body">
    <div class="info__wrapper">
      <h3>{{ $t("users_detail_verification_info_title") }}</h3>
      <div class="info__container">
        <div>
          <div class="fields-group__header">{{ $t("status_title") }}</div>
          <v-select
            v-if="resourceDetail.status && !resourceDetail.first_name"
            :class="`status small ${resourceDetail.status} outlined`"
            :options="statusOptions"
            v-model="resourceDetail.status"
            @change="$emit('onUpdateResource', resourceDetail)"
          />
          <span v-else :class="`status_${resourceDetail.status}`">
            {{ resourceDetail.status | clean_cap }}
          </span>
        </div>
        <div>
          <div class="fields-group__header">
            {{ $t("users_detail_verification_info_manual_review_status") }}
          </div>
          <span :class="`status_${resourceDetail.manual_review_status}`">
            {{ resourceDetail.manual_review_status | clean_cap }}
          </span>
        </div>

        <div>
          <div class="fields-group__header">
            {{ $t("users_detail_checks_error_details") }}
          </div>
          <div class="info__container-errors__wrapper">
            <span
              class="ERROR capitalized"
              v-for="(error, i) of resourceDetail.errors"
              :key="i"
            >
              {{ error ? $t(error.toLowerCase()) : error | clean_cap_error }}
            </span>
          </div>
        </div>
        <div>
          <div class="fields-group__header">
            {{ $t("settings_company_info_last_updated") }}
          </div>
          {{ resourceDetail.updated_at | dateTime }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  name: "PADetailVerificationInfo",
  props: {
    resourceDetail: { required: true },
    statusOptions: { required: true },
  },
});
</script>
<style lang="stylus" scoped>
@import '~@/styles/global/theme.styl';

.info__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: $spacing-md;

  &-errors__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-sm;
  }
}
</style>
