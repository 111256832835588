

























import Vue from "vue";

export default Vue.extend({
  name: "PaginationControls",
  props: {
    totalPages: { type: Number, required: true },
    currentPage: { type: Number, required: true },
    showAllPages: { required: true, type: Boolean },
  },
  methods: {
    changePage(page: number) {
      this.$emit("updatePage", page);
    },
  },
});
