<template>
  <div class="info__container extracted_info">
    <div v-if="data" class="fields-group">
      <div>
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_document_type") }}
        </div>
        <i
          v-if="
            permissionType &&
            VerificationsMap[VERIFICATIONS_TYPES_MAP[permissionType]]
          "
          class="verification_type-icon"
          :class="`v-icon fad ${
            VerificationsMap[VERIFICATIONS_TYPES_MAP[permissionType]].icon
          } status_${status} large`"
        >
        </i>
        <span v-if="permissionType" class="capitalized">
          {{ permissionType | clean_cap }}
        </span>
      </div>
      <div
        v-if="data.first_name || data.name || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_first_name") }}
        </div>
        <div>
          <VExpandInput
            v-if="data.name"
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.name"
            placeholder="-"
          />
          <VExpandInput
            v-else-if="
              data.first_name || data.first_name === '' || displayNullValues
            "
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.first_name"
            placeholder="-"
          />
        </div>
      </div>
      <div
        v-if="data.last_name || data.last_name || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_last_name") }}
        </div>
        <div>
          <VExpandInput
            v-if="data.surname"
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.surname"
            placeholder="-"
          />
          <VExpandInput
            v-else
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.last_name"
            placeholder="-"
          />
        </div>
      </div>
      <div
        v-else-if="data.patronimic || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_patronimic") }}
        </div>
        <div>
          <VExpandInput
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.patronimic"
            placeholder="-"
          />
        </div>
      </div>
      <div
        v-if="data.birth_date || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_birth_date") }}
        </div>
        <div>
          <div
            v-if="!editing"
            class="v-input v-text-field v-expand-input small v-input--no-label view-field capitalized"
          >
            <div class="v-input__slot">
              <span>{{ data.birth_date | date }}</span>
            </div>
          </div>
          <VExpandInput
            v-else
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.birth_date"
            placeholder="YYYY-MM-DD"
          />
        </div>
      </div>
      <div
        v-if="data.nationality || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_user_nationality") }}
        </div>
        <div>
          <VExpandInput
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.nationality"
            placeholder="-"
          />
        </div>
      </div>
      <div
        v-if="data.personal_number || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_personal_number") }}
        </div>
        <div>
          <div>
            <VExpandInput
              class="small"
              :class="{ 'view-field capitalized': !editing }"
              :editable="editing"
              v-model="data.personal_number"
              placeholder="-"
            />
          </div>
        </div>
      </div>
      <div
        v-if="data.document_number || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_document_number") }}
        </div>
        <div v-if="data.number || data.document_number || displayNullValues">
          <div>
            <VExpandInput
              v-if="data.document_number"
              class="small"
              :class="{ 'view-field capitalized': !editing }"
              :editable="editing"
              v-model="data.document_number"
              placeholder="-"
            />
            <VExpandInput
              v-else
              class="small"
              :class="{ 'view-field capitalized': !editing }"
              :editable="editing"
              v-model="data.number"
              placeholder="-"
            />
          </div>
        </div>
      </div>
      <div
        v-if="data.country || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_document_country") }}
        </div>
        <div>
          <VExpandInput
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.country"
            placeholder="-"
          />
        </div>
      </div>
      <div
        v-if="data.expiration_date || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_expiration_date") }}
        </div>
        <div>
          <div
            v-if="!editing"
            class="v-input v-text-field v-expand-input small v-input--no-label view-field capitalized"
          >
            <div class="v-input__slot">
              <span>{{ data.expiration_date | date }}</span>
            </div>
          </div>
          <VExpandInput
            v-else
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.expiration_date"
            placeholder="YYYY-MM-DD"
          />
        </div>
      </div>

      <div
        v-if="data.address || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_address") }}
        </div>
        <div>
          <VExpandInput
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.address"
            placeholder="-"
          />
        </div>
      </div>
      <div v-if="data.city || displayNullValues" class="field-group__wrapper">
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_city") }}
        </div>
        <div>
          <VExpandInput
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.city"
            placeholder="-"
          />
        </div>
      </div>
      <div
        v-if="data.province || displayNullValues"
        class="field-group__wrapper"
      >
        <div class="fields-group__header">
          {{ $t("users_detail_extracted_info_province") }}
        </div>
        <div>
          <VExpandInput
            class="small"
            :class="{ 'view-field capitalized': !editing }"
            :editable="editing"
            v-model="data.province"
            placeholder="-"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {
  VerificationsMap,
  VERIFICATIONS_TYPES_MAP,
} from "@/models/verificationsTypes";
import VExpandInput from "@/components/vuemarc-ui-kit/inputs/VExpandInput.vue";

export default Vue.extend({
  components: { VExpandInput },
  name: "PADetailExtractedInfo",
  props: {
    data: { required: true },
    status: { required: true },
    permissionType: { required: true },
    displayNullValues: { required: false, default: true },
    editing: { required: false },
    type: { required: true },
  },
  data() {
    return {
      VerificationsMap,
      VERIFICATIONS_TYPES_MAP,
    };
  },
});
</script>
<style lang="stylus" scoped>
@import '~@/styles/global/theme.styl';

.field-group__wrapper {
  align-items: center;
}

.verification_type-icon {
  margin-left: $spacing-sm;
}

.v-expand-input:not(.view-field) {
  min-width: 200px;
}
</style>
