



































































































































import Vue from "vue";
import { fieldOptions, tableValues } from "../../../models/cdp/cdpModels";
import PADetailCDPInput from "../PADetailCDPInput.vue";
import {
  DiscountAndFeeChangesDifference,
  LineItemChangesDifference,
  TaxChangesDifference,
} from "@/models/cdp";

interface InjectedProvide {
  rawTableValues: () => tableValues;
}

export default Vue.extend({
  name: "PADetailCDPTableGeneric",
  components: { PADetailCDPInput },
  props: {
    tableValues: {
      required: true,
      type: Object as () => tableValues,
    },
    fieldOptions: { required: true, type: Object as () => fieldOptions },
    addTableRowCopy: { required: false, type: String },
    inputPopoverPlacement: { required: true, type: String },
    colspan: { required: false, default: 1, type: Number },
    currentPage: { required: true, type: Number },
    pageSize: { required: true, type: Number },
    showAllPages: { required: true, type: Boolean },
  },
  inject: {
    rawTableValues: {
      default: () => ({} as tableValues),
    },
  },
  data() {
    return {
      draggedRowIndex: null as null | number,
      draggedRowId: null as null | string,
      rawTableValuesInjected: (
        this as unknown as InjectedProvide
      ).rawTableValues(),
    };
  },
  computed: {
    numberSumTotals(): any {
      const tableValuesData = (
        this as unknown as InjectedProvide
      ).rawTableValues();
      if (!tableValuesData.rows?.length) return null;

      const totalRows = this.initializeTotalRows();
      for (const row of tableValuesData.rows) {
        for (const key of Object.keys(row) as Array<
          | keyof TaxChangesDifference
          | keyof LineItemChangesDifference
          | keyof DiscountAndFeeChangesDifference
        >) {
          const cell = row[key as keyof typeof row];
          if (this.shouldSumCell(cell, key)) {
            if (typeof cell === "object" && cell !== null && "value" in cell) {
              totalRows[key] += cell.value;
            }
          }
        }
      }

      return totalRows;
    },
  },
  methods: {
    getRowIndex(rowId: string) {
      for (let i = 0; i < this.rawTableValuesInjected.rows.length; i++) {
        const row = this.rawTableValuesInjected.rows[i];
        if (row.id === rowId) return i;
      }
    },
    initializeTotalRows() {
      const tableValuesData = (
        this as unknown as InjectedProvide
      ).rawTableValues();
      const totalRows = {} as any;

      if (tableValuesData.rows?.length > 0) {
        for (const key in tableValuesData.rows[0]) {
          totalRows[key] = null;
        }
      } else return null;

      return totalRows;
    },
    shouldSumCell(cell: any, key: string): boolean {
      if (["id", "id_next", "page_index"].includes(key)) return false;
      return (
        cell.value && this.isRowKeyANumber(cell.key) && !key.includes("percent")
      );
    },
    onDragStart(event: DragEvent, row: any, visualIndex: number) {
      this.draggedRowId = row.id;
      this.draggedRowIndex = visualIndex;

      event.dataTransfer!.effectAllowed = "move";
      event.dataTransfer!.setData("text/plain", row.id);
    },
    onDragOver(event: DragEvent) {
      event.preventDefault();
      event.dataTransfer!.dropEffect = "move";
    },
    onDrop(event: DragEvent, targetRow: any) {
      event.preventDefault();

      const tableValuesData = (
        this as unknown as InjectedProvide
      ).rawTableValues();

      if (!this.draggedRowId || this.draggedRowId === targetRow.id) {
        this.draggedRowId = null;
        this.draggedRowIndex = null;
        return;
      }

      const allRows = tableValuesData.rows as tableValues["rows"];
      const draggedIndex = allRows.findIndex(
        (row: any) => row.id === this.draggedRowId
      );
      const dropIndex = allRows.findIndex(
        (row: any) => row.id === targetRow.id
      );

      if (
        draggedIndex !== -1 &&
        dropIndex !== -1 &&
        draggedIndex !== dropIndex
      ) {
        const draggedRow = allRows.splice(draggedIndex, 1)[0];
        allRows.splice(dropIndex, 0, draggedRow);
        this.updateRowLinks(allRows);
      }

      this.draggedRowId = null;
      this.draggedRowIndex = null;
    },
    onDragEnd() {
      this.draggedRowId = null;
      this.draggedRowIndex = null;
    },
    updateRowLinks(rows: any[]) {
      rows.forEach((row, idx) => {
        if (this.hasIdAndIdNext(row)) {
          if (idx < rows.length - 1 && this.hasIdAndIdNext(rows[idx + 1])) {
            row.id_next.value = rows[idx + 1].id;
          } else {
            row.id_next.value = null;
          }
        }
      });
    },
    hasIdAndIdNext(
      row: any
    ): row is { id: string; id_next: { value: string | null } } {
      return (
        row &&
        typeof row.id === "string" &&
        row.id_next &&
        (typeof row.id_next.value === "string" || row.id_next.value === null)
      );
    },
    isRowKeyANumber(key: string) {
      if (
        [
          "id",
          "id_next",
          "page_index",
          "bill_of_landing_reference",
          "identifier",
          "product_description",
          "product_name",
          "uom_description",
          "uom",
          "percent",
          "amount",
        ].includes(key)
      )
        return false;
      return true;
    },
  },
});
